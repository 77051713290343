import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { HashRouter } from 'react-router-dom'
import axios from 'axios';
import { UserProvider } from './context/user.context';


axios.defaults.baseURL = process.env.REACT_APP_API_URL;

ReactDOM.render(
  <HashRouter hashType={"noslash"}>
    <UserProvider>
      <App />
    </UserProvider>
  </HashRouter>,
  document.getElementById('root')
);