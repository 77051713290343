import React from 'react'

export default function Contact() {
    return (

        <div className="my-5">
            <h2 style={{ "color": "#666" }}>Contact Us
            </h2>
            <hr />

            <p className="my-5" style={{ "fontSize": "18px" }}>
                Viatris Middle East FZ-LLC, <br /><br />
                Atlas Business Center Building, Floor6, <br /><br />
                Dubai Media City, Al Falak Street, <br /><br />
                Dubai, United Arab Emirates,<br /><br />
                TEL: +971.4.5121500/598 FAX: +971.4.5802508
            </p>
        </div>
    )
}
