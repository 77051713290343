import React, { useContext, useEffect, useState } from 'react'
import { GeoAltFill, PersonFill, ShieldShaded } from 'react-bootstrap-icons'
import { UserContext } from '../../../context/user.context';
import { getDateTime } from '../../../Functions';
import { v4 as uuidv4 } from 'uuid';
import './Style.scss'

export default function Show() {

    const { user } = useContext(UserContext);
    const [infos, setInfos] = useState([]);

    useEffect(() => {
        setInfos([
            { name: 'Username', value: user.login || '' },
            { name: 'First Name', value: user.firstname || '' },
            { name: 'Last Name', value: user.lastname || '' },
            { name: 'Mail', value: user.email || '' },
            { name: 'Country ', value: user.country == "AE" ? "UAE" : user.country || '' },
            { name: 'City', value: user.city || '' },
            { name: 'Phone number', value: user.mobile || '' },
            { name: 'License Authority', value: user.licenseHealthcare || '' },
            { name: 'License Expiry date', value: getDateTime(new Date(user.licenseExpiryDate)) || '' },
            { name: 'License Number', value: user.licenseNumber || '' },
            // { name: 'Profession ', value: user.profession || '' },
            // { name: 'Target CME collected from i-Pharmacy Academy', value: user.targetCME || '' }
        ]);

    }, [user]);

    return (
        <div className="show_edit_user">

            <div className="row">
                <div className="col-md-4">
                    <div>
                        <img src={(user.picture || window.location.origin + '/images/default-picture.png') + `?id=${uuidv4()}`} className="img-thumbnail w-75" alt="" />
                    </div>
                    <div>
                        <div className="d-flex my-2">
                            <div className="me-2"><PersonFill /></div>
                            <div>{user.login}</div>
                        </div>
                        <div className="d-flex my-2">
                            <div className="me-2"><GeoAltFill /></div>
                            <div>{user.country}, {user.city}</div>
                        </div>
                        <div className="d-flex my-2">
                            <div className="me-2"><ShieldShaded /></div>
                            <div>HCP</div>
                        </div>
                    </div>
                </div>
                <div className="col-md-8">
                    {infos.map((inf) => (
                        <div className="row mb-3" key={inf.name}>
                            <div className="col-5" style={{ fontWeight: "bold" }}>{inf.name} :</div>
                            <div className="col-7">{inf.value}</div>
                        </div>
                    ))}


                </div>

            </div>

        </div>
    )
}
