import axios from "axios";
import React, { useState, useRef, useContext, useEffect } from "react";
import { X } from "react-bootstrap-icons";
import { UserContext } from "../../../context/user.context";
import { swal } from "../../../Functions";
import { v4 as uuidv4 } from "uuid";
import "./Style.scss";

function UploadButton() {
  const { isLoggedIn, user } = useContext(UserContext);

  const [imgSrc, setImgSrc] = useState(user.picture);
  const inputRef = useRef(null);

  const handleUpload = (e) => {
    inputRef.current?.click();
  };
  const handleDisplayFileDetails = () => {
    var file = inputRef.current?.files && inputRef.current.files[0];

    if (file.size < 1000000) {
      var reader = new FileReader();
      var url = reader.readAsDataURL(file);

      reader.onloadend = function (e) {
        setImgSrc(reader.result);
        axios
          .post("user/setpicture", { picture: reader.result })
          .then(({ data }) => {
            if (data.success && data.success === 1) {
              isLoggedIn();
            }
          });
      }.bind(this);
    } else {
      swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Max picture size is 1024KB",
        timer: 3000
      });
    }
  };

  const removePicture = () => {
    setImgSrc(null);

    axios.post("user/setpicture", { picture: null }).then(({ data }) => {
      if (data.success && data.success === 1) {
        isLoggedIn();
      }
    });
  };

  useEffect(() => {
    setImgSrc(user.picture);
  }, [user]);

  return (
    <div className="my-3 text-center">
      <div className="d-flex justify-content-center">
        <div className="profile__img__container">
          <img
            src={
              (imgSrc ||
                window.location.origin + "/images/default-picture.png") +
              `?id=${uuidv4()}`
            }
            className="img-thumbnail w-100"
            alt="profile"
            onClick={handleUpload}
            style={{ cursor: "pointer" }}
          />
          {imgSrc && (
            <X className="delete__profile__img" onClick={removePicture} />
          )}
        </div>
      </div>
      <input
        ref={inputRef}
        onChange={handleDisplayFileDetails}
        className="d-none"
        type="file"
        accept="image/png, image/gif, image/jpeg"
      />
      {/* <button
        onClick={handleUpload}
        className={`ms-3 btn btn-outline-${
          uploadedFileName ? "success" : "primary"
        }`}
      >
        {uploadedFileName ? uploadedFileName : "Upload"}
      </button> */}
      <hr />
    </div>
  );
}

export default UploadButton;
