import { Route, Redirect } from "react-router-dom"

export default function AuthRoute({isAuth, fetchStatus, ...props}) {
    
    if(!isAuth && fetchStatus === "done") {
        return <Redirect to="/" />
    }

    return <Route {...props} />
}
