import axios from "axios";
import React, { useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { swal } from "../../Functions";
import useForm from "./Form/useForm";
import validateInfo from "./Form/validateInfo";
import "./Style.scss";

export default function RegisterUser({ props, setLoading }) {
  const { handleChange, handleSubmit, values, errors } = useForm(
    submitForm,
    validateInfo
  );
  const [alert, setAlert] = useState("");

  function submitForm() {
    setAlert("");
    setLoading(true);
    axios.post("user/registration", values).then(({ data }) => {
      if (data.success !== undefined && data.success === 0) {
        setAlert(data.message);
      } else {
        swal.fire({
          position: "top-end",
          icon: "success",
          title: "Email has been sent to you",
          showConfirmButton: false,
          timer: 3000,
        });
        props.history.push("/");
      }
      setLoading(false);
    });
  }

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-2">
          <Form.Label>
            Username <span className="text-danger">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            onChange={handleChange}
            value={values.username}
            className={errors.username && "err"}
            name="username"
          />
          {errors.username && (
            <span className="text-danger">{errors.username}</span>
          )}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            E-mail address <span className="text-danger">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            onChange={handleChange}
            value={values.email}
            className={errors.email && "err"}
            name="email"
          />

          {errors.email && <span className="text-danger">{errors.email}</span>}
        </Form.Group>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>
                First Name <span className="text-danger">*</span>{" "}
              </Form.Label>
              <Form.Control
                type="text"
                onChange={handleChange}
                value={values.fname}
                className={errors.fname && "err"}
                name="fname"
              />

              {errors.fname && (
                <span className="text-danger">{errors.fname}</span>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>
                Last Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                onChange={handleChange}
                value={values.lname}
                className={errors.lname && "err"}
                name="lname"
              />

              {errors.lname && (
                <span className="text-danger">{errors.lname}</span>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>
                Country <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                onChange={handleChange}
                defaultValue={values.country}
                className={errors.country && "err"}
                name="country"
              >
                <option>- Select a value -</option>
                <option value="ae">UAE</option>
              </Form.Select>

              {errors.country && (
                <span className="text-danger">{errors.country}</span>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>
                City <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                onChange={handleChange}
                value={values.city}
                className={errors.city && "err"}
                name="city"
              />

              {errors.city && (
                <span className="text-danger">{errors.city}</span>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>
                License Healthcare Authority{" "}
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                onChange={handleChange}
                defaultValue={values.licenseHealthcare}
                className={errors.licenseHealthcare && "err"}
                name="licenseHealthcare"
              >
                <option>- Select a value -</option>
                <option value="DHA (20)">DHA (20)</option>
                <option value="MOH (20)">MOH (20) </option>
                <option value="DHA (20)">DOH (20)</option>
              </Form.Select>

              {errors.licenseHealthcare && (
                <span className="text-danger">{errors.licenseHealthcare}</span>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Mobile</Form.Label>
              <Form.Control
                type="text"
                onChange={handleChange}
                value={values.mobile}
                className={errors.mobile && "err"}
                name="mobile"
              />

              {errors.mobile && (
                <span className="text-danger">{errors.mobile}</span>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>
                License Expiry date <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="date"
                onChange={handleChange}
                value={values.licenseExpiryDate}
                className={errors.licenseExpiryDate && "err"}
                name="licenseExpiryDate"
              />

              {errors.licenseExpiryDate && (
                <span className="text-danger">{errors.licenseExpiryDate}</span>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>
                Profession <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                onChange={handleChange}
                defaultValue={values.profession}
                className={errors.profession && "err"}
                name="profession"
              >
                <option>- Select a value -</option>
                <option value="Pharmacist">Pharmacist</option>
              </Form.Select>

              {errors.profession && (
                <span className="text-danger">{errors.profession}</span>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>
                Confirm License Expiry date{" "}
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="date"
                onChange={handleChange}
                value={values.confirmLicenseExpiryDate}
                className={errors.confirmLicenseExpiryDate && "err"}
                name="confirmLicenseExpiryDate"
              />

              {errors.confirmLicenseExpiryDate && (
                <span className="text-danger">
                  {errors.confirmLicenseExpiryDate}
                </span>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>
                License Number <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                onChange={handleChange}
                value={values.licenseNumber}
                className={errors.licenseNumber && "err"}
                name="licenseNumber"
              />

              {errors.licenseNumber && (
                <span className="text-danger">{errors.licenseNumber}</span>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="text-secondary">
              Please re-enter your license expiration date to confirm, be aware
              that when you change your expiration date, your CME points will be
              reset to 0.
            </p>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>
                Target CME collected from i-Pharmacy Academy{" "}
                <span className="text-danger">*</span>{" "}
              </Form.Label>
              <Form.Control
                type="text"
                onChange={handleChange}
                value={values.targetCME}
                className={errors.targetCME && "err"}
                name="targetCME"
              />

              {errors.targetCME && (
                <span className="text-danger">{errors.targetCME}</span>
              )}
            </Form.Group>
          </Col>
        </Row>

        <div className="form-check">
          <input
            type="checkbox"
            id="flexCheckChecked"
            onChange={handleChange}
            defaultChecked={false}
            className="form-check-input"
            name="confirm"
          />
          <label className="form-check-label small" htmlFor="flexCheckChecked">
            I confirm that I am a Pharmacist{" "}
            <span className="text-danger">*</span>
          </label>
        </div>

        <div className="form-check">
          <input
            type="checkbox"
            id="flexCheckChecked2"
            onChange={handleChange}
            defaultChecked={false}
            className="form-check-input"
            name="confirm2"
          />
          <label className="form-check-label small" htmlFor="flexCheckChecked2">
            I have read and accept the{" "}
            <Link to="/cookies-and-privacy-policy">Privacy Policy</Link>{" "}
            <span className="text-danger">*</span>
          </label>
        </div>

        <div className="form-check mb-3">
          <input
            type="checkbox"
            id="flexCheckChecked3"
            onChange={handleChange}
            defaultChecked={false}
            className="form-check-input"
            name="confirm3"
          />
          <label className="form-check-label small" htmlFor="flexCheckChecked3">
          I confirm, I have read the Viatris Privacy Notice at <a href="https://www.viatris.com/en/privacy-policy" target="_blank">www.viatris.com/en/privacy-policy</a> and agree that Viatris Inc., its affiliates and subsidiaries (“Viatris”) may use my personal data to provide me with scientific information and information on pharmaceutical products as well as events, webinars or surveys. I understand and acknowledge that all information processed by Viatris is handled in accordance with local privacy laws and may be captured and processed in countries outside of the jurisdiction in which it was collected. In order to improve its emails, websites and information services, Viatris may track whether I received an email, opened it, clicked on any links, viewed or downloaded any documents within it. I understand that my consent is voluntary and I can withdraw my consent at any time. The withdrawal of consent will not affect the lawfulness of processing based on consent before its withdrawal. I acknowledge that I am not obliged to provide my personal information. However, if I do not provide the information, I may not, for example, be able to participate fully in activities managed by Viatris”.
            <span className="text-danger">*</span>
          </label>
        </div>

        {alert && <div className="alert alert-danger">{alert}</div>}

        <Button
          variant="success"
          type="submit"
          disabled={!values.confirm || !values.confirm2 || !values.confirm3}
        >
          Create new account
        </Button>
      </Form>
    </div>
  );
}
