import React, { useContext } from 'react'
import { Container, Nav, Navbar, ProgressBar } from 'react-bootstrap'
import { ArrowDown } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'
import { UserContext } from '../../context/user.context'
import { diffTodayDate, getDateTime, getTime } from '../../Functions'
import { NavBrand } from './Components'
import './Header.scss'


export default function Header({ isAuth }) {

    const { user } = useContext(UserContext);
    
    return (
        <header >
            <Navbar collapseOnSelect expand="xl" bg="light" variant="light" id="navabar_">
                {isAuth ?
                    (<Container fluid >
                        <NavBrand isAuth={isAuth} />
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto">
                            </Nav>
                            <Nav className="header_right">

                                <Nav.Item>
                                    <div className="license_container">
                                        <div className="license_desc">
                                            <span>License Expiry in Days <ArrowDown /> </span>
                                        </div>

                                        <div className="license_num">
                                            {getTime(user.licenseExpiryDate)}
                                        </div>


                                    </div>

                                </Nav.Item>
                                {/* <Nav.Item>
                                    <div className="progress_container">
                                        <div className="progress_">
                                            <ProgressBar now={user.collectedCME} max={user.targetCME} />
                                        </div>
                                        <div className="row">
                                            <div className="col-5">
                                                <div className="text-primary">{user.collectedCME}</div>
                                                <div>Collected CME points</div>
                                            </div>
                                            <div className="col-2"> VS </div>
                                            <div className="col-5">
                                                <div className="text-primary">{user.targetCME}</div>
                                                <div>Personal Target</div>
                                            </div>
                                        </div>

                                    </div>
                                </Nav.Item> */}
                                <Nav.Item>
                                    <div className="progress_container">
                                        <div className="progress_">
                                            <ProgressBar  now={user.collectedCME} max={30}  />
                                        </div>
                                        <div className="row">
                                            <div className="col-5">
                                                <div className="text-primary">{user.collectedCME}</div>
                                                <div>Collected CME points</div>
                                            </div>
                                            <div className="col-2"> VS </div>
                                            <div className="col-5">
                                                <div className="text-primary">30</div>
                                                <div>Total CME points</div>
                                            </div>
                                        </div>
                                    </div>
                                </Nav.Item>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>)
                    :
                    (<Container >
                        <NavBrand isAuth={isAuth} />
                            <Nav className="me-auto">
                            </Nav>
                            <Nav className="d-flex flex-row">
                                <div><Link to="/user/login">Login</Link></div>
                                <div><Link to="/user/register" className="ms-3">Register</Link></div>
                            </Nav>
                    </Container>)}
            </Navbar>
        </header>
    )
}
