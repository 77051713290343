import { Route, Redirect } from "react-router-dom"
import Footer from "../components/Footer/Footer"

export default function PrivateRoute({ isAuth, ...props }) {

    if (isAuth) {
        return <Redirect to="/dashboard" />
    }

    return (<div><div className="container"><Route {...props} /></div> <Footer /></div>)
}
