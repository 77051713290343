import React, { useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import ChangePassword from '../../components/Login/ChangePassword'
import LoginUser from '../../components/Login/LoginUser'
import RegisterUser from '../../components/Login/RegisterUser'
import ForgotPassword from '../../components/Login/ForgotPassword'
import "./Style.scss"

export default function Login(props) {
    const [loading, setLoading] = useState(false);
    return (
        <div className="user_form_container">
            {loading && <div id="loading"><img src={window.location.origin + '/images/Spinne.gif'} alt="" /></div>}
            <div className="user_form">
                {props.validateAccount ?
                        <ChangePassword props={props} token={props.match.params.token} changePassword={props.changePassword} /> :
                        <>
                            <h4>User account</h4>
                            <Tabs defaultActiveKey={props.match.params.type ?? 'login'} id="uncontrolled-tab-example" className="mb-3">
                                <Tab eventKey="register" title="Create new account">
                                    <RegisterUser props={props} setLoading={setLoading} />
                                </Tab>
                                <Tab eventKey="login" title="Log in">
                                    <LoginUser  setLoading={setLoading}/>
                                </Tab>
                                <Tab eventKey="password" title="Request new password">
                                    <ForgotPassword />
                                </Tab>
                            </Tabs>
                        </>
                }
            </div>

        </div>
    )
}

