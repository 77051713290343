import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ProgressBar, Spinner } from "react-bootstrap";
import { UserContext } from "../../../context/user.context";
import moment from "moment";
import { addOneYear } from "../../../Functions";

export default function Module() {
  const { prgId, modId } = useParams();
  const [module, setModule] = useState(null);
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (user && user.programs) {
      user.programs.forEach((prg) => {
        if (prg.id === parseInt(prgId)) {
          prg.modules.forEach((mod) => {
            if (mod.id === parseInt(modId)) setModule(mod);
          });
        }
      });
    }
  }, [user]);
  
  return (
    <div id="modDetailts">
      {module ? (
        <>
          <div style={{borderRadius: "15px", overflow: "hidden"}}><img src={module.picture || window.location.origin + "/images/default-module.jpg"} alt="module background" className="w-100" /></div>
          <a
            href="javascript:;"
            onClick={() => {
              if (module) window.open(module.url, "_self");
            }}
          >
            <div className="card mt-2">
              <div className="card-body">
                <h5 className="card-title">
                  {module.name}
                </h5>
                <div className="mod_info">
              <span>Duration : 40-45 min</span>{" "}
              <span>Awarded {module.modulecmepoints} points</span>{" "}
              <span>
                Valid till{" "}
                {moment(addOneYear(new Date(module.helddate))).format("DD MMM YY")}
              </span>{" "}
            </div>
                <p className="card-text text-secondary mt-3" style={{textAlign: "justify"}}>
                  {module.description}
                </p>
                <button
                    className="btn btn-primary btn-sm float-right"
                    onClick={() => {
                      if (module) window.open(module.url, "_self");
                    }}
                  >
                    Launch Module
                  </button>
              </div>
            </div>
          </a>
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
}
