import React, { useContext, useEffect, useState } from 'react'
import { NavLink, Route } from "react-router-dom"
import { Link } from 'react-router-dom';
import CompletedCourses from '../../components/Dashboard/CompletedCourses';
import CompletionHistory from '../../components/Dashboard/CompletionHistory';
import FooterIntern from "../../components/Footer/FooterIntern";
import Help from '../../components/Dashboard/Help';
import Home from '../../components/Dashboard/Home';
import MedicalCourses from '../../components/Dashboard/modules/MedicalCourses';
import Module from '../../components/Dashboard/modules/Module';
import OngoingCourses from '../../components/Dashboard/OngoingCourses';
import EditUser from '../../components/Dashboard/User/EditUser';
import Watchlist from '../../components/Dashboard/Watchlist';
import { UserContext } from '../../context/user.context';
import { v4 as uuidv4 } from 'uuid';
import './Dashboard.scss'
import { ArrowLeftSquareFill, BoxArrowRight, Person } from 'react-bootstrap-icons';
import { NavDropdown } from 'react-bootstrap';

export default function Dashboard() {

    const [dashboardHeight, setDashboardHeight] = useState(window.innerHeight);
    const { logOut } = useContext(UserContext)
    const { user } = useContext(UserContext);
    const [isCollabse, setIsCollabse] = useState(false);
    

    useEffect(() => {
        let dash = document.getElementById('dashboar_container');
        function handleResize() {
            setDashboardHeight(window.innerHeight);
            if (window.innerWidth <= 1207) dash.classList.add("collapse_nav");
            else dash.classList.remove("collapse_nav");
        }
        function handleScroll() {
            const scrollHeigt = window.pageYOffset;

            if (scrollHeigt >= 88) dash.classList.add("side_fixed");
            else dash.classList.remove("side_fixed");
        }
        if (dash) {
            window.addEventListener('resize', handleResize);
            window.addEventListener('scroll', handleScroll);
        }
    }, []);

    const sideBarCollapse = () => {
        let dash = document.getElementById('dashboar_container');
        if (dash) dash.classList.toggle("collapse_nav");
        setIsCollabse(dash.classList.contains("collapse_nav"));
    }

    return (
        <div id="dashboar_container" className="d-flex" style={{ minHeight: dashboardHeight }}>

            <section className="sidebar_container" style={{ height: dashboardHeight }}>

                <div>
                    <div className="user_img p-3 d-flex align-items-center position-relative">

                    <div className='side_bar_trigger' onClick={sideBarCollapse}><ArrowLeftSquareFill /></div>
                    
                        <div style={{ width: '80px', height: '80px', borderRadius: '50%', overflow: 'hidden', background: '#fff' }}><img src={(user.picture || window.location.origin + '/images/default-picture.png') + `?id=${uuidv4()}`} alt="profile" style={{ height: '100%', width: '100%', objectFit: 'contain' }} /></div>


                        <NavDropdown
                            id="profile_dropdown"
                            title={user.login}
                            menuVariant="dark"
                            className='profile_dropdown'
                        >
                            <Link to="/dashboard/user/edit" className="dropdown-item"> 
                                <div className='d-flex align-items-center'>
                                    <div style={{ fontSize: '3px', cursor: 'pointer' }}>
                                        <Person/>
                                    </div>
                                    <div className='ms-2'>Profile</div>
                                </div>
                                </Link>
                            <NavDropdown.Item href="#" onClick={logOut}>
                                <div className='d-flex align-items-center'>
                                    <div style={{ fontSize: '3px', cursor: 'pointer' }}>
                                        <BoxArrowRight />
                                    </div>
                                    <div className='ms-2'>Log Out</div>
                                </div>
                            </NavDropdown.Item>
                        </NavDropdown>

                        {/* <div className='text-uppercase ms-3 text-light'>
                            <div>{user.login}</div>
                            <div className='bg-light py-2 px-3'>
                                <div className='text-dark'>
                                    <div className='d-flex align-items-center'>
                                        <div style={{ fontSize: '3px', cursor: 'pointer' }}>
                                            <BoxArrowRight onClick={logOut} className="text-danger" />
                                        </div>
                                        <div className='text-black ms-2'>Log Out</div>
                                    </div>
                                </div>
                                <div>

                                </div>
                            </div>
                        </div> */}

                    </div>

                    <nav>
                        <ul className="list-group mt-4 first-ul">
                            <li className="list-group-item">
                                <NavLink to="/dashboard" className={({isActive}) => (isActive && "active")} exact>Home</NavLink>
                            </li>
                            <li className="list-group-item drop">
                                <span className="p-3">My Courses </span>
                                <ul className="list-group second_ul">
                                    <li className="list-group-item">
                                        <NavLink to="/dashboard/completion-history" className={({isActive}) => (isActive && "active" || "not")}>Newly Added Courses</NavLink>
                                    </li>
                                    <li className="list-group-item">
                                        <NavLink to="/dashboard/ongoing-courses" className={({isActive}) => (isActive && "active" || "not")}>Ongoing courses</NavLink>
                                    </li>
                                    <li className="list-group-item">
                                        <NavLink to="/dashboard/completed-courses" className={({isActive}) => (isActive && "active" || "not")}>Completed Courses</NavLink>
                                    </li>
                                    <li className="list-group-item">
                                        <NavLink to="/dashboard/watchlist" className={({isActive}) => (isActive && "active" || "not")}>Watchlist</NavLink>
                                    </li>
                                </ul>
                            </li>
                            <li className="list-group-item">
                                {/* <NavLink to="/dashboard/help" className="p-3">Help</NavLink> */}
                                {/* <Link to="#">Help</Link> */}
                            </li>
                        </ul>
                    </nav>
                </div>

                <section className="logo_container">
                    <div className="logo_">
                        <div className='text-center'><img className="img-responsive" src={window.location.origin + '/images/Viatris-logo-white.png'} alt="" /></div>
                        <span className="approval-code">VIAG-2023-0581</span>
                    </div>
                </section>

            </section>

            <section className="content_wrapper">
                <div className="bg-primary">
                    {/* <div className="p-3 d-flex align-items-center justify-content-between">
                        <div>
                            <Justify onClick={sideBarCollapse} style={{ color: '#fff', fontSize: '25px', cursor: 'pointer' }} />
                        </div>
                        <div className="d-flex">
                            <div>
                                <div className="input-group">
                                    <div className="form-outline">
                                        <input type="search" placeholder="Search" className="form-control rounded-0" />
                                    </div>
                                    <button type="button" className="btn btn-success">
                                        <Search />
                                    </button>
                                </div>
                            </div>

                            <div className='text-light ms-3' style={{ fontSize: '22px', cursor: 'pointer' }}>
                                <BoxArrowRight onClick={logOut} />
                            </div>

                        </div>
                    </div> */}
                </div>
                <div className="content p-3">
                    <Route path="/dashboard" exact render={() => (<Home />)} />
                    <Route path="/dashboard/ongoing-courses" exact render={() => (<OngoingCourses />)} />
                    <Route path="/dashboard/completed-courses" exact render={() => (<CompletedCourses />)} />
                    <Route path="/dashboard/completion-history" exact render={() => (<CompletionHistory />)} />
                    <Route path="/dashboard/watchlist" exact render={() => (<Watchlist />)} />
                    <Route path="/dashboard/help" exact render={() => (<Help />)} />
                    <Route path="/dashboard/user/edit" exact render={() => (<EditUser />)} />

                    <Route path="/dashboard/program/:prgId" exact render={props => <MedicalCourses {...props}  isCollabse={isCollabse}/>} />
                    <Route path="/dashboard/module/:prgId/:modId" exact><Module /></Route>

                </div>
                
                <FooterIntern />
            </section>

        </div>
    )
}
