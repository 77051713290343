import { Button, Form } from 'react-bootstrap'
import React, { useState } from 'react'
import axios from 'axios';
import { Check, X } from 'react-bootstrap-icons'
import { swal } from '../../../Functions';

export default function Password() {

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [validatation, setValidatation] = useState({});
    const [alert, setAlert] = useState('');
    const [progress, setProgress] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        const requestPWD = async () => {
            setProgress(true);
            setAlert('');
            if (validatation.lawer && validatation.capital && validatation.number && validatation.length && validatation.confirm && validatation.specialChar) {
                await axios.post('user/update', { password }).then(({ data }) => {
                    if (data.success === 0) setAlert(data.message);
                    else {
                        swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: 'Your profile has been updated',
                            showConfirmButton: false,
                            timer: 3000
                        });
                    }
                });
            }
            setProgress(false);
        }
        requestPWD();
    }


    const handleChange = () => {

        let validatation_ = { lawer: false, capital: false, number: false, length: false, confirm: false, specialChar: false };

        // Validate lowercase letters
        let lowerCaseLetters = /[a-z]/g;
        if (password.match(lowerCaseLetters)) {
            validatation_.lawer = true;
        }

        // Validate capital letters
        let upperCaseLetters = /[A-Z]/g;
        if (password.match(upperCaseLetters)) validatation_.capital = true;

        // Validate numbers
        let numbers = /[0-9]/g;
        if (password.match(numbers)) validatation_.number = true;

        //special character
        let specialChar = /[^a-zA-Z0-9]/;
        if(specialChar.test(password)) validatation_.specialChar = true;
        
        // Validate length
        if (password.length >= 8) validatation_.length = true;

        if (password !== '' && password === confirmPassword) validatation_.confirm = true;

        setValidatation(validatation_);
    }


    return (
        <div>

            <Form onSubmit={handleSubmit}>
                <div className='col-md-6'>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} onKeyUp={handleChange} value={password} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control type="password" placeholder="Confirm Password" onChange={e => setConfirmPassword(e.target.value)} onKeyUp={handleChange} value={confirmPassword} />
                    </Form.Group>

                    {alert && <div className="alert alert-danger">{alert}</div>}

                    <div>
                        <h6>Password must contain the following:</h6>
                        <div>{validatation.lawer ? <Check className="verify_icon" /> : <X className="times_icon" />} A lowercase letter</div>
                        <div>{validatation.capital ? <Check className="verify_icon" /> : <X className="times_icon" />} A capital (uppercase) letter</div>
                        <div>{validatation.number ? <Check className="verify_icon" /> : <X className="times_icon" />} A number</div>
                        <div>{validatation.length ? <Check className="verify_icon" /> : <X className="times_icon" />} Minimum 8 characters</div>
                        <div>{validatation.specialChar ? <Check className="verify_icon" /> : <X className="times_icon" />} At least one special character</div>
                        <div>{validatation.confirm ? <Check className="verify_icon" /> : <X className="times_icon" />} Password confirmation match password</div>
                    </div>

                    <Button className="mt-3" variant="primary" type="submit" disabled={progress}>
                        {progress && 'Updating...' || 'Save'}
                    </Button>
                </div>
            </Form>


        </div>
    )
}