import { diffTodayDate } from '../../../Functions'

export default function validateInfo(values) {
  let errors = {};

  const checkIfvalid = (value, text) => {
    let err = '';
    
    if (!value.trim()) {
      err = text + ' field is required.';
    }
    
    if (!/^[A-Za-z]+/.test(value.trim())) {
      err = 'Enter a valid ' + text;
    }

    return err;
  }

  if (checkIfvalid(values.username, 'username') !== '') errors.username = checkIfvalid(values.username, 'username');
  if (checkIfvalid(values.fname, 'first Name') !== '') errors.fname = checkIfvalid(values.fname, 'first Name');
  if (checkIfvalid(values.lname, 'last Name') !== '') errors.lname = checkIfvalid(values.lname, 'last Name');

  if (!values.country.trim()) errors.country = 'country field is required.';
  if (checkIfvalid(values.city, 'city') !== '') errors.city = checkIfvalid(values.city, 'city');

  if (!values.licenseHealthcare.trim()) errors.licenseHealthcare = 'License healthcare authority field is required.';

  if (!values.licenseExpiryDate.trim()) errors.licenseExpiryDate = 'license expiry date field is required.';
  if (!values.profession.trim()) errors.profession = 'profession  field is required.';
  
  if(diffTodayDate(values.licenseExpiryDate) <= 0) errors.licenseExpiryDate = 'license expiry date should be greater than today.';
  else{
    if (!values.confirmLicenseExpiryDate.trim()) errors.confirmLicenseExpiryDate = 'confirm license expiry date field is required.';
    if (values.confirmLicenseExpiryDate.trim() !== values.licenseExpiryDate.trim()) errors.confirmLicenseExpiryDate = 'Please confirm your license expiry date.';
  }
  
  if (!values.licenseNumber.trim()) errors.licenseNumber = 'license number field is required.';
  
  if(values.targetCME === '')errors.targetCME = 'Target CME collected from i-Pharmacy Academy field is required.';

  if (!values.email) {
    errors.email = 'Email required';
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid';
  }

  return errors;
}
