import React from 'react'
import { BoxArrowInDownRight, QuestionCircle, Search } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'
import "./Style.scss"

export default function Help() {
    return (
        <div>
            <div className="mt-3 bg-primary text-light p-4 rounded-1">
                <h4>Help</h4>
                <h6>Do you need a help?</h6>
            </div>

            <div className="bg-light p-4 py-5 rounded-1 mt-4 help_container">
                <div className="row">
                    <div className="col-lg-4">
                        <div>
                            <h4>
                                Login and Registration <BoxArrowInDownRight />
                            </h4>
                            <div><Link>Login</Link></div>
                            <div><Link>Registration (Create new account)</Link></div>
                            <div><Link>Request a new password</Link></div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div>
                            <h4>
                            Overview <Search />
                            </h4>
                            <div><Link>Home</Link></div>
                            <div><Link>My-Dashboard</Link></div>
                            <div><Link>My Courses</Link></div>
                            <div><Link>Help Page</Link></div>
                            <div><Link>Profile page</Link></div>
                            <div><Link>Quiz page</Link></div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div>
                            <h4>
                            How to <QuestionCircle />
                            </h4>
                            <div><Link>Edit profile</Link></div>
                            <div><Link>Take course</Link></div>
                            <div><Link>Take quiz</Link></div>
                            <div><Link>Download certificate</Link></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
