import axios from 'axios';
import React, { useContext } from 'react';
import { Dropdown, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { UserContext } from '../../../context/user.context';
import { generatePDF, getDateTime } from '../../../Functions';

export default function ModulesTable({ modules, type }) {
    const { user, isLoggedIn } = useContext(UserContext);
    // const [loading, setLoading] = useState(false);
    const imgUrl = window.location.origin;

    const watchlistrigger = async (isWL, modulekey) => {
        if (isWL) {
            await axios.post('module/removewatchlist', { modulekey });
        } else {
            await axios.post('module/addwatchlist', { modulekey });
        }
        isLoggedIn();
    }

    const triggerGeneratePDF = async (lname, fname, module, date, CME, el, type, accnumber, helddate) => {
        // setLoading(true);
        const src = el.src;
        el.src= imgUrl + '/images/Spinne.gif';
        generatePDF(lname, fname, module, date, CME, el, src, type, accnumber, helddate);
    }
    
    return <Table striped={true} hover style={{ marginBottom: '0' }}>
        <thead>
            <tr>
                <th>Course Title</th>
                <th>CME</th>
                <th>{type === 'ongoing' && 'Date Started' || 
                    type === 'completed' &&'Completion Date' ||
                    'Date Added' }</th>
                <th className='text-center'>{ type === 'completed' &&  'Download Certificate'}</th>
            </tr>
        </thead>
        <tbody>
            {modules.map(mod => (
                <tr key={mod.id}>
                    <td>{mod.name}</td>
                    <td>{mod.modulecmepoints}</td>
                    <td>{type === 'watchlist' ? mod.watchlistAddedDate : getDateTime(new Date(mod.completiondate || mod.firstaccess || mod.cdate))}</td>
                    <td className='text-center'>
                        {(type === 'ongoing') &&
                            (<Link className='btn btn-primary btn-sm' to={`/dashboard/module/${mod.prgId}/${mod.id}`}>
                                Continue
                            </Link>)

                            ||
                            (type === 'completed' &&
                                (<>
                                    <button
                                        onClick={(e) => triggerGeneratePDF(user.lastname, user.firstname, mod.name, mod.completiondate, mod.modulecmepoints, e.target, 'COMPLETION', mod.accnumber, mod.helddate)}
                                        style={{ all: 'unset', marginLeft: '10px', cursor: 'pointer' }}>
                                        <img
                                            src={imgUrl + `/images/SVG/certificate_attendance.svg`}
                                            alt='certificate Completion' width={25}
                                        /></button>
                                </>)
                                || (
                                    <Dropdown >
                                        <Dropdown.Toggle variant="dark-bleu" id="dropdown-basic">
                                            Select
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <div className='m-2'>
                                                <Link className='d-block' to={`/dashboard/module/${mod.prgId}/${mod.id}`}>
                                                    Course details
                                                </Link>
                                            </div>
                                            <div className='m-2'>
                                                <Link to='#' onClick={() => watchlistrigger(mod.watchlist, mod.key)}>
                                                    {mod.watchlist && 'Remove From Watchlist' || 'Add to Watchlist'}
                                                </Link>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                )
                            )

                        }

                    </td>
                </tr>
            ))}

        </tbody>
    </Table>;
}
