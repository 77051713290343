import moment from "moment/moment";
import React from "react";
import { Link } from "react-router-dom";
import { addOneYear } from "../../../Functions";
import "./Style.scss";

export default function ModuleComponent({ prgId, module }) {
  const statusArr = {
    notstart: { status: "Not Started", bg: "bg-dark-bleu", icon: (<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
    <path strokeLinecap="round" strokeLinejoin="round" d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z" />
    </svg>
    ) },
      inprogress: { status: "In Progress", bg: "bg-primary", icon: (<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>  
    ) },
      complete: { status: "Completed", bg: "bg-success", icon: (<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
    ) },
  };
  const descArray = module.description.split(" ")
  const link = `/dashboard/module/${prgId}/${module.id}`;
  return (
      <div className="card">
        <Link to={link}><div className="card-image-wrapper">
        <img
            src={
              module.picture ||
              window.location.origin + "/images/default-module.jpg"
            }
            className="card-img"
            alt="..."
          />
        <div className="card_img_overlay">
          <div
            className={`${
              statusArr[module.status].bg
            } module_status`}
          >
            {statusArr[module.status].status} {" "} {statusArr[module.status].icon}
          </div>

          <h5 className="card-title">{module.name}</h5>
        </div>
        </div></Link>
        <div className="card-body">
          <div className="mod_info mb-2">
            <span>Duration : 40-45 min</span>{" "}
            <span>Awarded {module.modulecmepoints} points</span>{" "}
            <span>
              Valid till{" "}
              {moment(addOneYear(new Date(module.helddate))).format("DD MMM YY")}
            </span>
          </div>
          
          <p className="card-text text-secondary" style={{textAlign: "justify"}}>{
            descArray.length > 22 ?
            (<>{descArray.map((str, key) => {
              if(key < 21) {
                return <span key={key}>{str + " "}</span>
              }
            })} ...<Link to={link} className="text-decoration-underline">Learn more</Link></> )  :
            module.description
          }</p>
        </div>
      </div>
  );
}

