import axios from "axios";
import React, { useLayoutEffect, useEffect, useState } from "react";
import { findGetParameter, swal } from "../Functions";

//user
export const UserContext = React.createContext({});

export const UserProvider = ({ children }) => {

  const isNewModulesComing = parseInt(process.env.REACT_APP_UNPUBLISH_ALL) === 1;
  const [user, setUser] = useState({});
  const [isAuth, setIsAuth] = useState(false);
  const [fetchStatus, setfetchStatus] = useState("pending");

  const isLoggedIn = async () => {
    await refreshToken();

    const loginToken = localStorage.getItem("loginToken");
    axios.defaults.headers.common["Authorization"] = "bearer " + loginToken;
    if (loginToken) {
      const { data } = await axios.get("user/getme");
      let userData = {};
      if (data && data.success !== 0) {
        const programs = await axios.get("programs");
        if (programs.data && programs.data.success !== 0) {
          let completed = [];
          let ongoing = [];
          let last = [];
          let all = [];
          let watchlist = [];

          let collectedPoints = 0;

          programs.data.map((prg) => {

            prg.modules.map((mod) => {
              mod.prgId = prg.id;

              if (mod.status === "complete") {
                completed.push(mod);
                collectedPoints += parseFloat(mod.cmepoints);
              }
              
              if (!isNewModulesComing) {
                if (mod.status === "inprogress") ongoing.push(mod);
                if (mod.watchlist) watchlist.push(mod);
                else if (mod.status === "notstart") last.push(mod);
                all.push(mod);
              }
            });
          });

          userData = data;
          userData.programs = programs.data;
          userData.modules = { completed, ongoing, last, watchlist, all };
          userData.collectedCME = collectedPoints;

          setUser(userData);
          setIsAuth(true);
        } else {
          swal.fire({
            position: "top-end",
            icon: "error",
            title: "An Error Has Occurred",
            showConfirmButton: false,
            timer: 3000,
          });
          logOut();
        }
      } else setIsAuth(false);
    } else setIsAuth(false);

    setfetchStatus("done");
  };

  const refreshToken = async () => {
    const token = findGetParameter("token");
    if (token != null) {
      const { data } = await axios.post("token/refresh_fromcallback", {
        token,
      });
      if (data.access_token) {
        localStorage.setItem("loginToken", data.access_token);
      }
    }
  };
  const logOut = () => {
    localStorage.removeItem("loginToken");
    setIsAuth(false);
  };

  const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  };

  useEffect(() => {
    isLoggedIn();
  }, []);

  const contextValue = {
    user,
    isLoggedIn,
    logOut,
    fetchStatus,
    isAuth,
    setfetchStatus,
    useWindowSize,
    isNewModulesComing
  };

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};
