import React, { useContext } from "react";
import { UserContext } from "../../context/user.context";
import CompletedCourses from "./modules/coursesComponent";

export default function CompletionHistory() {
  const { user } = useContext(UserContext);
  const { modules } = user;
  return (
    <div>
      <CompletedCourses courses={modules.completed} />
    </div>
  );
}
