import axios from 'axios';
import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap'

export default function ForgotPassword() {

  const [email, setEmail] = useState('');
  const [error, setError] = useState({ success: -1, messsage: '' });
  const [emailSent, setEmailSent] = useState(false);
  const [progress, setProgress] = useState(false);

  const submit = (e) => {
    e.preventDefault();
    const requestPWD = async () => {
      setProgress(true);
      setEmail(email.replace(/\s/g, ''));
      if (email.replace(/\s/g, '') === '') {
        setError({ success: 0, messsage: 'Email field is required' });
      } else {
        await axios.post('user/forgotpassword', { email }).then(res => {
          let errObj = {};
          errObj.success = res.data.success;
          errObj.messsage = res.data.success === 1 ? 'Email has been sent' : 'User not found';
          setEmailSent(res.data.success === 1);
          setError(errObj);
        });
      }
      setProgress(false);
    }
    requestPWD();
  }

  return <div>
    {emailSent ? (
      <div className='text-center'>
        <h4 className='text-uppercase'>password reset</h4>
        <p className='lead px-5'>You should receive a link in a few moments. Please open that link to reset your password.
        </p>
        <p className='fw-bold'>{email}</p>
      </div>
    ) :
      (<Form onSubmit={submit}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control type="text" placeholder="Enter email" onChange={e => setEmail(e.target.value)} value={email} />
          <p className='mt-2'>Enter your email to receive instructions on how to reset your password.</p>
        </Form.Group>

        {error.messsage !== '' && <div className={`alert alert-${error.success === 0 ? 'danger' : 'success'}`}>{error.messsage}</div>}

        <Button variant="primary" type="submit" disabled={progress}>
          {progress && 'sending...' ||'Reset'}
        </Button>
      </Form>)
    }

  </div>;
}
