import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import { UserContext } from "../../../context/user.context";
import UploadButton from "./UploadButton";
import axios from "axios";
import { getDateTime, swal } from "../../../Functions";
import validateInfo from "../../Login/Form/validateInfo";

export default function Edit() {
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({});
  const { user, isLoggedIn } = useContext(UserContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    let err = validateInfo(values);

    setErrors(err);
    if (Object.keys(err).length === 0) {
      if (
        values.licenseExpiryDate !==
        getDateTime(new Date(user.licenseExpiryDate))
      )
        values.collectedCME = 0;
      axios.post("user/update", values).then((res) => {
        swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your profile has been updated",
          showConfirmButton: false,
          timer: 3000,
        });

        isLoggedIn();
      });
    }
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "confirm") value = e.target.checked;

    if (
      name === "fname" ||
      name === "lname" ||
      name === "city" ||
      name === "username"
    )
      value = value.replace(/[^a-zA-Z ]/g, "");

    setValues({
      ...values,
      [name]: value,
    });
  };

  useEffect(() => {
    setValues({
      id: user.id,
      username: user.login || "",
      email: user.email || "",
      fname: user.lastname || "",
      lname: user.firstname || "",
      country: user.country || "ae",
      city: user.city || "",
      licenseExpiryDate: getDateTime(new Date(user.licenseExpiryDate)) || "",
      confirmLicenseExpiryDate:
        getDateTime(new Date(user.licenseExpiryDate)) || "",
      licenseHealthcare: user.licenseHealthcare || "DHA (20)",
      licenseNumber: user.licenseNumber || "",
      mobile: user.mobile || "",
      profession: user.profession || "Pharmacist",
      targetCME: user.targetCME || "",
    });
  }, [user]);

  return (
    <div>
      <div className="row">
        <Form onSubmit={handleSubmit}>
          <UploadButton />

          <Row>
            <Col>
              <Form.Group className="mb-2">
                <Form.Label>
                  Username <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  readOnly
                  value={values.username}
                  className={errors.username && "err"}
                  name="username"
                />
                {errors.username && (
                  <span className="text-danger">{errors.username}</span>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>
                  E-mail address <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  readOnly
                  value={values.email}
                  className={errors.email && "err"}
                  name="email"
                />

                {errors.email && (
                  <span className="text-danger">{errors.email}</span>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>
                  First Name <span className="text-danger">*</span>{" "}
                </Form.Label>
                <Form.Control
                  type="text"
                  onChange={handleChange}
                  value={values.fname}
                  className={errors.fname && "err"}
                  name="fname"
                />

                {errors.fname && (
                  <span className="text-danger">{errors.fname}</span>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>
                  Last Name <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  onChange={handleChange}
                  value={values.lname}
                  className={errors.lname && "err"}
                  name="lname"
                />

                {errors.lname && (
                  <span className="text-danger">{errors.lname}</span>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>
                  Country <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  onChange={handleChange}
                  defaultValue={values.country}
                  className={errors.country && "err"}
                  name="country"
                >
                  {/* <option>- Select a value -</option> */}
                  <option value="ae">UAE</option>
                </Form.Select>

                {errors.country && (
                  <span className="text-danger">{errors.country}</span>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>
                  City <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  onChange={handleChange}
                  value={values.city}
                  className={errors.city && "err"}
                  name="city"
                />

                {errors.city && (
                  <span className="text-danger">{errors.city}</span>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>
                  License Healthcare Authority{" "}
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  onChange={handleChange}
                  defaultValue={values.licenseHealthcare}
                  className={errors.licenseHealthcare && "err"}
                  name="licenseHealthcare"
                >
                  {/* <option>- Select a value -</option> */}
                  <option value="DHA (20)">DHA (20)</option>
                  <option value="MOH (20)">MOH (20) </option>
                  <option value="DHA (20)">DOH (20)</option>
                </Form.Select>

                {errors.licenseHealthcare && (
                  <span className="text-danger">
                    {errors.licenseHealthcare}
                  </span>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Mobile</Form.Label>
                <Form.Control
                  type="number"
                  onChange={handleChange}
                  value={values.mobile}
                  className={errors.mobile && "err"}
                  name="mobile"
                />

                {errors.mobile && (
                  <span className="text-danger">{errors.mobile}</span>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>
                  License Expiry date <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="date"
                  onChange={handleChange}
                  value={values.licenseExpiryDate}
                  className={errors.licenseExpiryDate && "err"}
                  name="licenseExpiryDate"
                />

                {errors.licenseExpiryDate && (
                  <span className="text-danger">
                    {errors.licenseExpiryDate}
                  </span>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>
                  Profession <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  onChange={handleChange}
                  defaultValue={values.profession}
                  className={errors.profession && "err"}
                  name="profession"
                >
                  {/* <option>- Select a value -</option> */}
                  <option value="Pharmacist">Pharmacist</option>
                </Form.Select>

                {errors.profession && (
                  <span className="text-danger">{errors.profession}</span>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>
                  Confirm License Expiry date{" "}
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="date"
                  onChange={handleChange}
                  value={values.confirmLicenseExpiryDate}
                  className={errors.confirmLicenseExpiryDate && "err"}
                  name="confirmLicenseExpiryDate"
                />

                {errors.confirmLicenseExpiryDate && (
                  <span className="text-danger">
                    {errors.confirmLicenseExpiryDate}
                  </span>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>
                  License Number <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="number"
                  onChange={handleChange}
                  value={values.licenseNumber}
                  className={errors.licenseNumber && "err"}
                  name="licenseNumber"
                />

                {errors.licenseNumber && (
                  <span className="text-danger">{errors.licenseNumber}</span>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="text-secondary">
                Please re-enter your license expiration date to confirm, be
                aware that when you change your expiration date, your CME points
                will be reset to 0.
              </p>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>
                  Target CME collected from i-Pharmacy Academy{" "}
                  <span className="text-danger">*</span>{" "}
                </Form.Label>
                <Form.Control
                  type="number"
                  onChange={handleChange}
                  value={values.targetCME}
                  className={errors.targetCME && "err"}
                  name="targetCME"
                />

                {errors.targetCME && (
                  <span className="text-danger">{errors.targetCME}</span>
                )}
              </Form.Group>
            </Col>
          </Row>
          {/* 
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" onChange={handleChange} value={values.pwd} className={errors.pwd && 'err'} name="pwd" />

                                {errors.pwd && <span className="text-danger">{errors.pwd}</span>}
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Confirm password</Form.Label>
                                <Form.Control type="text" onChange={handleChange} value={values.confirm_pwd} className={errors.confirm_pwd && 'err'} name="confirm_pwd" />

                                {errors.confirm_pwd && <span className="text-danger">{errors.confirm_pwd}</span>}
                            </Form.Group>
                        </Col>
                    </Row> */}

          {/* <div className="form-check mb-3">
                        <input type="checkbox" id="flexCheckChecked" onChange={handleChange} defaultChecked={false} className='form-check-input' name="confirm" />
                        <label className="form-check-label" for="flexCheckChecked">
                            I confirm that I am a Pharmacist <span className="text-danger">*</span>
                        </label>
                    </div> */}

          <Button variant="success" type="submit">
            Save
          </Button>
        </Form>
      </div>
    </div>
  );
}
