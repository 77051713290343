import React from 'react'
import { Link } from 'react-router-dom'
import "./Landing.scss"

export default function Landing() {
    return (
        <div id="landing_">
            <div className="row">
                <div className="col-lg-6">
                    <div className="img_container left_section">
                        <Link to="/user/login">
                            <img src={window.location.origin + '/images/36404553.png'} width="540" height="668" alt="" />

                            <div className="absolute-layer">
                                <div className="name-field">
                                    <span className="field-content">An Academy designed for you</span>        </div>
                                <div className="description-field">
                                    <div className="field-content"><p>Enhance your capabilities for better patient care</p>
                                    </div>
                                </div>
                            </div>

                        </Link>
                    </div>
                </div>
                <div className="col-lg-6">

                    <div className="img_container">

                        <Link to="/user/login">
                            <img src={window.location.origin + '/images/21170367.png'} width="550" height="325" alt="" />

                            <div className="absolute-layer">
                                <div className="name-field">
                                    <span className="field-content">Medical Courses</span>        </div>
                                <div className="description-field">
                                    <div className="field-content"><p>A knowledge center across key therapeutic area</p>
                                    </div>
                                </div>
                            </div>

                        </Link>
                    </div>
                    <div className="img_container last_box">
                        <Link to="/user/login"> <img src={window.location.origin + '/images/54412046.png'} width="550" height="325" alt="" />

                            <div className="absolute-layer">
                                <div className="name-field">
                                    <span className="field-content">Non Medical Courses</span>        </div>
                                <div className="description-field">
                                    <div className="field-content"><p>Enhance your managerial role with your patients</p>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}