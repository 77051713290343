import { useState, useEffect } from "react";
import { getDateTime } from "../../../Functions";

const useForm = (callback, validate) => {

    const [values, setValues] = useState({
        username: '',
        email: '',
        fname: '',
        lname: '',
        country: '',
        city: '',
        licenseHealthcare: '',
        mobile: '',
        licenseExpiryDate: getDateTime(),
        profession: '',
        confirmLicenseExpiryDate: '',
        licenseNumber: '',
        targetCME: '',
        confirm: false,
        confirm2: false
    });

    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = (e) => {
        let { name, value } = e.target
        if (name === 'confirm' || name === 'confirm2' || name === 'confirm3') value = e.target.checked

        if(name === 'fname' || name === 'lname'|| name === 'city'|| name === 'username')
        value = value.replace(/[^a-zA-Z ]/g, "");

        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit = e => {
        e.preventDefault();
        setErrors(validate(values));
        setIsSubmitting(true);
    };

    useEffect(
        () => {
            if (Object.keys(errors).length === 0 && isSubmitting) {
                callback();
            }
        },[errors]);

    return { handleChange, handleSubmit, values, errors };
};
export default useForm;