import axios from 'axios';
import React, { useRef, useState } from 'react'
import { Form, Tab, Tabs, Button } from 'react-bootstrap'
import { Check, X } from "react-bootstrap-icons";
import { swal } from '../../Functions';

export default function ChangePassword({ props, token, changePassword }) {

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [validatation, setValidatation] = useState({});
    const [alert, setAlert] = useState('');

    
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setAlert('');
        if (validatation.lawer && validatation.capital && validatation.number && validatation.length && validatation.confirm && validatation.specialChar) {            
            await axios.post('user/updatepassword', { password, email, token }).then(({ data }) => {
                if (data.success === 0) setAlert(data.message);
                else {
                    swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Your password has been saved',
                        showConfirmButton: false,
                        timer: 3000
                    })
                    return props.history.push('/user/login');
                }
            });
        }
        setLoading(false);
    }
    
    const handleChange = () => {

        let validatation_ = { lawer: false, capital: false, number: false, length: false, confirm: false, specialChar: false};

        // Validate lowercase letters
        let lowerCaseLetters = /[a-z]/g;
        if (password.match(lowerCaseLetters)) {
            validatation_.lawer = true;
        }

        // Validate capital letters
        let upperCaseLetters = /[A-Z]/g;
        if (password.match(upperCaseLetters)) validatation_.capital = true;

        // Validate numbers
        let numbers = /[0-9]/g;
        if (password.match(numbers)) validatation_.number = true;

        //special character
        let specialChar = /[^a-zA-Z0-9]/;
        if(specialChar.test(password)) validatation_.specialChar = true;
        
        // Validate length
        if (password.length >= 8) validatation_.length = true;

        if (password !== '' && password === confirmPassword) validatation_.confirm = true;

        setValidatation(validatation_);
    }
    
    return (
        <div>        
            <Tabs defaultActiveKey={'pwd'} id="uncontrolled-tab-example" className="mb-3">
                <Tab eventKey="pwd" title="Change Password">
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="text" placeholder="Email" onChange={e => setEmail(e.target.value)} value={email} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} onKeyUp={handleChange} value={password} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control type="password" placeholder="Confirm Password" onChange={e => setConfirmPassword(e.target.value)} onKeyUp={handleChange} value={confirmPassword} />
                        </Form.Group>

                        {alert && <div className="alert alert-danger">{alert}</div>}

                        <div>
                            <h6>Password must contain the following:</h6>
                            <div>{validatation.lawer ? <Check className="verify_icon" /> : <X className="times_icon" />} A lowercase letter</div>
                            <div>{validatation.capital ? <Check className="verify_icon" /> : <X className="times_icon" />} A capital (uppercase) letter</div>
                            <div>{validatation.number ? <Check className="verify_icon" /> : <X className="times_icon" />} A number</div>
                            <div>{validatation.length ? <Check className="verify_icon" /> : <X className="times_icon" />} Minimum 8 characters</div>
                            <div>{validatation.specialChar ? <Check className="verify_icon" /> : <X className="times_icon" />} At least one special character</div>
                            <div>{validatation.confirm ? <Check className="verify_icon" /> : <X className="times_icon" />} Password confirmation match password</div>
                        </div>

                        <Button className="mt-3" variant="primary" type="submit" disabled={loading}>
                            Save
                        </Button>
                    </Form>

                </Tab>
            </Tabs> 
        </div>
    )
}
