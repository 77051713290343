import React from 'react'

export default function Terms() {
    return (
        <div className="mt-4">
            <h2 style={{"color": "#666"}}>Terms of Use</h2>
            <hr />

            <p className="pb-5" style={{"fontSize": "16px"}}>This website belongs to <span style={{"fontWeight": "bold"}}>Viatris Middle East FZ LLC</span><br/><br/>


            Please read and review these Terms of Use carefully before accessing or using this website. By accessing or using this site, you acknowledge that you have read, understood and agreed to these Terms of Use. If you do not agree to the Terms of Use, you may not access or use the website. Viatris may alter these Terms of Use from time to time, so you should periodically check these Terms of Use as any changes will take effect as soon as they are posted to this website. <br/><br/>


            Use of Website. Your access to and use of the information contained in the website is subject to these Terms of Use. You agree to act lawfully at all times in using and accessing this website and you will not do anything which damages, interferes with, disrupt access to, interrupts or impairs the functionality of this website or inhibits other users’ use and enjoyment of the site.<br/><br/>


            <span style={{"fontWeight": "bold"}}>1.</span> Content: You agree that access to and use of this website and its content is at your own risk. Viatris may add, alter or delete materials from this website at any time at its discretion. Viatris will use reasonable efforts to include accurate and up-to-date information on this website but gives no warranties, undertakings or representations of any kind as to its accuracy, currency, quality, completeness or fitness for purpose. Viatris disclaims all warranties, express or implied, to the fullest extent permitted by law. Neither Viatris, its affiliates, nor any party involved in creating, producing or delivering this website shall be liable for any loss, damage or cost whatsoever, whether in contract, tort (including negligence) or otherwise arising from reliance on information contained in this website, access to, use of, or inability to use this website, or any errors or omissions in its content. This limitation includes any loss, damage or cost caused by any viruses that infect your computer equipment, software or data.<br/><br/>


            <span style={{"fontWeight": "bold"}}>2.</span> Cookies and Privacy: Viatris respects the privacy of its website users. Please refer to our Cookie and Privacy Policy which explains users' rights and the basis on which we may use any personal data (including by using "cookies") that is disclosed to us via this website.<br/><br/>


            <span style={{"fontWeight": "bold"}}>3.</span> Third Party Websites and Links: This website may contain links or references to other websites maintained by third parties over whom we have no control. Such links are provided merely as a convenience. Similarly, this website may be accessed from third party links over which Viatris has no control. Viatris makes no warranties, undertakings or representations of any kind as to the accuracy, currency, quality, completeness or fitness for purpose of any information contained in such websites and shall have no liability for any loss, damage or cost of any kind arising from such information. The inclusion of any third party link does not imply an endorsement or recommendation by Viatris.<br/><br/>


            <span style={{"fontWeight": "bold"}}>4.</span> Medical Information. This website may contain information relating to various medical conditions and their treatment. For those which do, this information is provided for general information only and is not meant to be a substitute for advice provided by a doctor or other qualified healthcare professional. You should not use the information on this website to diagnose a health or fitness problem or disease - always consult with a doctor or other qualified healthcare professional.<br/><br/>


            <span style={{"fontWeight": "bold"}}>5.</span> Non-Confidential Information. Subject to our Cookie and Privacy Policy, any communication or other material that you send to Viatris through the internet or post on the website by electronic mail or otherwise, such as any questions, comments and suggestions, is and will be deemed to be non-confidential and Viatris shall have no obligation of any kind with respect to it. Viatris shall be free to use any ideas, concepts, know-how or techniques contained in these communications and materials for any purpose whatsoever, including but not limited to, developing, manufacturing and marketing products.<br/><br/>


            <span style={{"fontWeight": "bold"}}>6.</span> Intellectual Property Rights. Viatris owns all copyright, patents, database rights, trade-marks, designs, know-how and confidential information (whether registered or not), and all other intellectual property rights existing in this website and its contents. The contents of this website may only be copied for non-commercial individual reference with all copyright or other proprietary notices retained, and thereafter may not be recopied, reproduced or otherwise redistributed. Except as expressly permitted above, you may not copy, display, download, distribute, modify, reproduce, republish or retransmit any information, text or documents contained in this website or any part of it in any electronic medium or in hard copy, or create any derivative work based on it, without Pfizer’s express written consent. In addition, the Viatris name and logo are registered trademarks, and may not be used without Pfizer’s written permission. The misuse of Pfizer’s trademarks or any other materials is prohibited and may be in breach of the local copyright law and trademark law. Please note that Viatris actively and aggressively enforces its intellectual property rights to the fullest extent of the law.<br/><br/>


            <span style={{"fontWeight": "bold"}}>7.</span> Miscellaneous. To the extent that any provisions of these Terms of Use are held to be illegal, invalid or unenforceable, then such provisions shall be severed and deleted without affecting the enforceability of the remaining provisions.
            </p>
        </div>
    )
}
