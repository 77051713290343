import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { UserContext } from "../../../context/user.context";
import "./Style.scss";
import ModuleComponent from "./ModuleComponent";

export default function MedicalCourses(props) {
  const { user, useWindowSize, isNewModulesComing } = useContext(UserContext);
  const [modules, setModules] = useState(null);
  const [allModules, setAllModules] = useState(null);
  const [filter, setFilter] = useState({ title: "", status: -1 });
  const [finished, setFinished] = useState(false);
  const [width] = useWindowSize();
  const prgId = props.match.params.prgId;

  useEffect(() => {
    user.programs.map((prg) => {
      if (prg.id === parseInt(prgId)) {
        if(isNewModulesComing) prg.modules = [];
        setModules(prg.modules);
        setAllModules(prg.modules);
        setFinished(true);
        return true;
      }
    });
  }, [user]);
  
  const handleChange = (e) => {
    let { name, value } = e.target;

    setFilter({
      ...filter,
      [name]: value,
    });
  };

  useEffect(() => {
    if (finished) {
      let filteredModules = [];

      if (filter.title !== "") {
        allModules.map((module) => {
          if (module.name.toLowerCase().indexOf(filter.title) >= 0)
            filteredModules.push(module);
        });
      } else {
        filteredModules = (allModules && allModules) || modules;
      }

      if (parseInt(filter.status) !== -1) {
        switch (parseInt(filter.status)) {
          case 0:
            filteredModules = filteredModules.filter(
              (mod) => mod.status === "notstart"
            );
            break;
          case 1:
            filteredModules = filteredModules.filter(
              (mod) => mod.status === "inprogress"
            );
            break;
          case 2:
            filteredModules = filteredModules.filter(
              (mod) => mod.status === "complete"
            );
            break;
          default:
        }
      }
      setModules(filteredModules);
    }
  }, [filter]);
  
  return (
    <div>
      <div>
        <div className="my-4">
          <Row className="g-2 justify-content-center">
            <Col lg="3">
              <FloatingLabel controlId="floatingInputGrid" label="Search">
                <Form.Control type="text" onKeyUp={handleChange} name="title" />
              </FloatingLabel>
            </Col>
            <Col lg="3">
              <FloatingLabel controlId="floatingSelectGrid" label="Status">
                <Form.Select
                  onChange={handleChange}
                  name="status"
                  defaultValue={filter.status}
                >
                  <option value="-1">All</option>
                  <option value="0">Newly Added</option>
                  <option value="1">Ongoing</option>
                  <option value="2">Completed</option>
                </Form.Select>
              </FloatingLabel>
            </Col>
          </Row>
        </div>

        <div className="row row-cols-1 row-cols-lg-2 row-cols-md-1 g-3">
          {modules &&
            modules.map((module) => (
              <div className={`col-lg-6 ${width < 1630 ? 'col-xl-6' : 'col-xl-4'}`} key={module.id}>
                <ModuleComponent prgId={prgId} module={module} />
              </div>
            ))}
        </div>
        {modules && modules.length === 0 && (
          <h2 className="text-center mt-5">{isNewModulesComing ? 'New Modules Coming Soon' : 'No courses'}</h2>
        )}
      </div>
    </div>
  );
}
