import React, { useContext } from "react";
import { Switch } from "react-router-dom";
import { Landing, Login, Dashboard } from './pages/index';
import Header from './components/Header/Header';
import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";
import { UserContext } from "./context/user.context";
import Terms from "./pages/Other/Terms";
import PrivacyPolicy from "./pages/Other/PrivacyPolicy";
import Contact from "./pages/Other/Contact";

function App() {

  const { isAuth, fetchStatus } = useContext(UserContext);

  return (
    <div>

      {fetchStatus === "pending" ? <><div id="loading"><img src={window.location.origin + '/images/Spinne.gif'} alt="" /></div></>
        :
        <>
          <Header isAuth={isAuth} />

          <Switch>
                 
            <PublicRoute isAuth={isAuth} exact path="/" render={props => <Landing {...props} />} />
            <PublicRoute isAuth={isAuth} path="/user/:type" exact render={props => <Login {...props} />} />
            <PublicRoute isAuth={isAuth} path="/user/register/:token" exact render={props => <Login {...props} validateAccount={true}  />} />
            <PublicRoute isAuth={isAuth} path="/user/password/:token" exact render={props => <Login {...props} validateAccount={true} changePassword={true}/>} />

            <PublicRoute isAuth={isAuth} path="/terms-of-use" exact render={props => <Terms {...props} />} />
            <PublicRoute isAuth={isAuth} path="/cookies-and-privacy-policy" exact render={props => <PrivacyPolicy {...props} />} />
            <PublicRoute isAuth={isAuth} path="/contact-us" exact render={props => <Contact {...props} />} />

            <PrivateRoute isAuth={isAuth} fetchStatus={fetchStatus} exact path="/dashboard" render={props => <Dashboard {...props} />} />
            <PrivateRoute isAuth={isAuth} fetchStatus={fetchStatus} exact path="/dashboard/*" render={props => <Dashboard {...props} />} />

          </Switch>
        </>
      }


    </div>
  );
}

export default App;
