import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../context/user.context";
import ModulesTable from "./modules/ModulesTable";
import "./Style.scss";

export default function Home() {
  const { user, isNewModulesComing } = useContext(UserContext);

  return (
    <div>
      <div className="mt-4 mx-2">
        <h3>Medical Courses</h3>
        <div className="row">
          {user.programs.map((program) => (
            <div className="col-lg-6 mt-3" key={program.id}>
              <Link
                to={`/dashboard/program/${program.id}`}
                className="course_card"
              >
                <div className="card_img">
                  <img src={program.picture} alt="" />
                </div>
                <div className="position-relative">
                  <button className="btn btn-secondary w-100 course_card_btn">
                    {program.name}
                  </button>
                </div>
              </Link>
            </div>
          ))}
        </div>

        <div className="row mt-5">
          <div className="col-lg-4">
            <Link to="/dashboard/completion-history">
              <div className="d-flex align-items-center justify-content-between card_status border-dark-bleu">
                <div className="text-dark-bleu">Newly Added Courses</div>
                <div className="ms-2">
                  <span className="bg-dark-bleu">
                    {user.modules.last.length}
                  </span>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-4">
            <Link to="/dashboard/ongoing-courses">
              <div className="d-flex align-items-center justify-content-between card_status border-primary">
                <div className="text-primary">Ongoing Courses</div>
                <div className="ms-2">
                  <span className="bg-primary">
                    {user.modules.ongoing.length}
                  </span>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-4">
            <Link to="/dashboard/completed-courses">
              <div className="d-flex align-items-center justify-content-between card_status border-success">
                <div className="text-success">Completed Courses</div>
                <div className="ms-2">
                  <span className="bg-success">
                    {user.modules.completed.length}
                  </span>
                </div>
              </div>
            </Link>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6 mt-5">
            <div className="course_cards">
              <div className="course_cards_header bg-dark-bleu">
                Newly Added Courses
              </div>
              <div className="course_cards_body">
                {user.modules.last.length === 0 ? (
                  <h5 className="text-center mt-3">{isNewModulesComing ? 'New Modules Coming Soon' : 'No new courses yet!'}</h5>
                ) : (
                  <ModulesTable modules={user.modules.last} type="last" />
                )}
              </div>
            </div>
          </div>

          <div className="col-lg-6 mt-5">
            <div className="course_cards">
              <div className="course_cards_header bg-primary">
                Ongoing Courses
              </div>
              <div className="course_cards_body">
                {user.modules.ongoing.length === 0 ? (
                  <h5 className="text-center mt-3">{isNewModulesComing ? 'New Modules Coming Soon' : 'No new courses yet!'}</h5>
                ) : (
                  <ModulesTable modules={user.modules.ongoing} type="ongoing" />
                )}
              </div>
            </div>
          </div>

          <div className="col-lg-6 mt-5">
            <div className="course_cards">
              <div className="course_cards_header bg-warning">Watchlist</div>
              <div className="course_cards_body">
                {user.modules.watchlist.length === 0 ? (
                  <h5 className="text-center mt-3">{isNewModulesComing ? 'New Modules Coming Soon' : 'No courses in watchlist!'}</h5>
                ) : (
                  <ModulesTable
                    modules={user.modules.watchlist}
                    type="watchlist"
                  />
                )}
              </div>
            </div>
          </div>

          <div className="col-lg-6 mt-5">
            <div className="course_cards">
              <div className="course_cards_header bg-success">
                Completed Courses
              </div>
              <div className="course_cards_body">
                {user.modules.completed.length === 0 ? (
                  <h5 className="text-center mt-3">
                    {isNewModulesComing ? 'New Modules Coming Soon' : 'No completed courses yet!'}
                  </h5>
                ) : (
                  <ModulesTable
                    modules={user.modules.completed}
                    type="completed"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
