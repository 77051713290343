import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";

export default function Footer() {
  return (
    <footer id="footer_">
      <div className="container my-4">
        <div className="row header_menu">
          <div className="col-md-9 text-center">
            <div className="header_menu_first">
              <div className="my-2">
                <img
                  src={window.location.origin + "/images/Viatris-logo.png"}
                  alt=""
                />
              </div>
              <div className="my-2">
                <Link to="/terms-of-use">Terms of Use</Link>
              </div>
              <div className="my-2">
                <Link to="/cookies-and-privacy-policy">Privacy Policy</Link>
              </div>
              <div className="my-2">
                <a
                  href="https://www.viatris.com/en/cookie-notice"
                  target="_blank"
                  rel="noreferrer"
                >
                  Cookies Notice
                </a>
              </div>
              <div className="my-2">
                <Link to="/contact-us">Contact Us</Link>
              </div>
              <div className="my-2">
                <a href="https://actando.atlassian.net/servicedesk/customer/portal/22" target="_blank" rel="noreferrer">Support</a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer_disclaimer mt-3">
          <div className="footer_content">
            <span>
              Copyright © {new Date().getFullYear()} Viatris Middle East FZ LLC. All rights reserved
            </span>
            <span>
              This portal is not intended for patients or for members of the general public. This portal and it's content are intended only for healthcare professionals residing in the United Arab Emirates. Viatris Middle East FZ LLC doesn't suggest or recommend the use of Viatris products in any manner other than as described in the Prescribing information.
            </span>
          </div>
          <div className="footer_id">
            {/* <span>VIAG-2023-0581</span> */}
          </div>
        </div>
      </div>
    </footer>
  );
}
