import { Tab, Tabs } from 'react-bootstrap'
import React from 'react'
import Show from './Show'
import Edit from './Edit'
import Password from './Password'

export default function EditUser() {
    return (
        <div>
            <Tabs defaultActiveKey="edit" className="mb-3">
                <Tab eventKey="view" title="View">
                    <Show/>
                </Tab>
                <Tab eventKey="edit" title="Edit">
                    <Edit/>
                </Tab>
                <Tab eventKey="password" title="Update Password">
                    <Password/>
                </Tab>
            </Tabs>
        </div>
    )
}