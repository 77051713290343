import React from 'react'
import { Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export function NavBrand({isAuth}) {

    return (
        <Navbar.Brand><Link to="/"><img src={window.location.origin + '/images/pharmacy-academy-logo.png'} alt="Home" className="d-inline-block align-top logo" /></Link> 
        {!isAuth ?? (<span className="welcome_text">Welcome to the i-Pharmacy Academy.</span>)}       
        </Navbar.Brand>
    )
}
