import React, { useContext, useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import axios from 'axios';
import { UserContext } from '../../context/user.context';

export default function LoginUser({ setLoading }) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("")

    const { isLoggedIn } = useContext(UserContext);

    const submitLogin = (e) => {
        e.preventDefault();
        setLoading(true);
        axios.post('login', { login: email, password }).then(({ data }) => {
            if (data && data.success !== 0) {
                localStorage.setItem('loginToken', data.access_token);
                isLoggedIn();
            } else {
                const errMsg = data.status != 500 ? data.message : "Incorrect login or password";
                setError(errMsg);
            }

            setLoading(false);
        });
    }

    return (
        <div>
            <Form onSubmit={(e) => submitLogin(e)}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Username / Email</Form.Label>
                    <Form.Control type="text" placeholder="Enter username" onChange={e => setEmail(e.target.value)} value={email} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} value={password} />
                </Form.Group>

                {error && (<div className="alert alert-danger">{error}</div>)}

                <Button variant="primary" type="submit">
                    Log in
                </Button>
            </Form>
        </div>
    )
}
