import React from 'react'

export default function PrivacyPolicy() {
    return (
        <div className="mt-4">
            <h2 style={{ "color": "#666" }}>Privacy Policy</h2>
            <hr />

            <p className="pb-5" style={{ "fontSize": "16px" }}>At <span style={{ "fontWeight": "bold" }}>Viatris</span>, we recognize the importance of protecting and are committed to respecting the privacy of information collected about our online visitors. Below we set out how the information that you disclose at this site will be held, what it will be used for, and details of our data protection policy. We also set out information on our use of cookies.<br /><br />


                We collect personally identifiable information, such as names, addresses, telephone numbers, and email addresses only when voluntarily provided by you when you register or otherwise voluntarily submit information to us. We will, of course, only process this information in accordance with this policy or as otherwise agreed to by you.<br /><br />


                By registering for any Viatris website, you agree that we may use the information you have provided in the following way:<br /><br />

                <ul>
                    <li className="my-3">Viatris will collect your information as a controller of the database and your details may be accessible to and used by other Viatris group companies. As Viatris is a global organization, your information may be transferred within or outside of the Gulf Countries where privacy laws may be less strict than in Gulf Countries. However, we will always employ appropriate technical security measures to protect your personal information to the same specification as defined in the local law and to ensure that it is not accessed by unauthorized persons.</li>
                    
                    <li className="my-3">Your information may be used by Viatris to provide you with printed and/or online publications, telephone messages, SMS messages and/or email communications (but only if you have agreed to receive these communications from us as part of the registration process for a particular website). These will contain tailored information as part of the program concerned.</li>
                    
                    <li className="my-3">
                    Viatris may also use your information to send you (including by email) other health-related information which may be of interest to you, and/or to conduct market research into medical conditions and the usefulness of Viatris’s health information programs (if you have consented to receive these communications from us).</li>
                    
                    <li className="my-3">
                    Viatris respects the confidentiality of personal information. Only Viatris, organizations working with Viatris in the administration of the above information, and Viatris group companies will have access to your information. We may use third-party processors to conduct research, process information or maintain websites, content, or programs on our behalf, but your information will not be disclosed to any other third parties such as outside mailing organizations. While it is unlikely, we may be required to disclose your information by court order or to comply with other legal or regulatory requirements. We will use reasonable efforts to notify you before we do so unless we are legally restricted from doing so.</li>
                    
                    <li className="my-3">
                    Your information will be retained by Viatris for the period of time that you are registered to one or more of our websites.</li>
                    
                    <li className="my-3">
                    You can unsubscribe from any Viatris website at any time by either clicking on the unsubscribe link or by referring to Viatris sales representative in your district. We aim to deal with your request (including unsubscribing you from any website) within 10 working days of its receipt.</li>
                    
                </ul>
                <span style={{ "fontWeight": "bold" }}>Use of cookies by Viatris</span>


                Viatris may collect information from you by using "cookies". Cookies are a small file of letters and numbers that we put on our computer if you agree. These cookies can tell us how and when pages in a website are visited and by how many people. With the information we receive through this technology, we hope to improve our sites. Although cookies do not normally contain personally identifiable information, if you have provided us with information about yourself, we may associate your registration information with cookies or other tracking utilities our website places on your computer's hard drive. Associating a cookie with your registration information allows us to offer increased personalization and functionality. Without cookies, this functionality would not be possible.<br/><br/>


                Your web browser can be set to allow you to control whether you will accept cookies, reject cookies, or to notify you each time a cookie is sent to you. If your browser is set to reject cookies, websites that are cookie-enabled will not recognize you when you return to the website, and some website functionality may be lost. The Help section of your browser will tell you how to prevent your browser from accepting cookies. To find out more about cookies, including how to see what cookies have been set and how to manage or delete them, visit <a href=" https://www.viatris.com/en/viatris-privacy-notice" target="_blank" rel="noreferrer"> https://www.viatris.com/en/viatris-privacy-notice.</a><br/><br/>


                In addition to Viatris cookies, certain third parties may deliver cookies to your computer for a variety of reasons. For example, we sometimes use Google Analytics and/or Web Trend which are web analytics tools that help us understand how visitors engage with our websites. Any third party links or advertising on our site may also use cookies; you may receive these cookies by clicking on the link to the third party site or advertising. We do not control the collection or use of information by these third parties, and these third party cookies are not subject to this Privacy Policy. You should contact these companies directly if you have any questions about their collection and/or use of information.<br/><br/>


                In addition, we may use other tracking systems like pixel-tags. Pixel tags sometimes called web beacons, which are similar in function to cookies but because of their insignificant size, they are not visible. However, they are used to pass certain information to our servers to personalize our websites and to track your usage across other Viatris websites. In addition, we may also use pixel tags in our HTML based emails.<br/>
                This Cookie and Privacy Policy may be amended by us at any time. Please check this website periodically to inform yourself of any changes.
            </p>
        </div>
    )
}
