import React, { useEffect, useState, useContext } from "react";
import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { UserContext } from "../../../context/user.context";
import ModuleComponent from "./ModuleComponent";

export default function CompletedCourses({ courses }) {
  const [mod, setModules] = useState(courses);
  const { useWindowSize, isNewModulesComing } = useContext(UserContext);
  const [filter, setFilter] = useState({ title: "", status: -1 });
  const [width] = useWindowSize();

  useEffect(() => {
    let filteredModules = [];

    if (filter.title !== "") {
      courses.map((module) => {
        if (module.name.toLowerCase().indexOf(filter.title) >= 0)
          filteredModules.push(module);
      });
    } else filteredModules = (courses && courses) || [];

    setModules(filteredModules);
  }, [filter]);

  const handleChange = (e) => {
    let { name, value } = e.target;
    setFilter({
      ...filter,
      [name]: value,
    });
  };

  return (
    <div>
      <div className="my-4">
        {courses && courses.length > 0 && (
          <Row className="g-2 justify-content-center">
            <Col lg="3">
              <FloatingLabel controlId="floatingInputGrid" label="Search">
                <Form.Control type="text" onKeyUp={handleChange} name="title" />
              </FloatingLabel>
            </Col>
          </Row>
        )}
      </div>

      <div className="row">
        {!isNewModulesComing && mod && mod.length > 0 ? (
          mod.map((module) => (
            <div
              className={`col-lg-6 ${
                width < 1630 ? "col-xl-6" : "col-xl-4"
              } d-flex align-items-stretch`}
              key={module.id}
            >
              <ModuleComponent prgId={module.prgId} module={module} />
            </div>
          ))
        ) : (
          <h3 className="text-center">{isNewModulesComing ? 'New Modules Coming Soon' : 'No courses yet!'}</h3>
        )}
      </div>
    </div>
  );
}
